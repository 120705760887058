import buildEnv from '../env.json';
import MeetingRoomOne from './Assets/Rooms/MeetingRoom1.jpg';
import AttendeeGuide from './Assets/Assets/VirtualEnvironment-AttendeeUserGuide.pdf';
import moment from 'moment';
// Unprotected Data

const colors = {
  // set default colors for proper render before initial event is set in redux
  main: '#122857',
  light: '#3b60ad',
  chatAccent: '#122857',
  iconGray: '#757575',
  disabledGray: '#e0e0e0',
  disbledText: '#a6a6a6',
  backgroundText: '#5A5A5A',
  backgroundGray: '#F2F2F2',
};

// Configure Default Fallbacks
const faviconLocation =
  'https://pci-admin-kfc-portal.s3.amazonaws.com/pci-icon.png'; // set fallback favicon

const landingPage = {
  // set fallback landing page content
  banner: 'https://pci-admin-kfc-portal.s3.amazonaws.com/Demo_Banner_v1a.png',
  content:
    'This is the Virtual Environment for the PlanetConnect Demo Environment!',
};

const title = 'Demo'; // fallback webpage title

const posterSessionTopicImages = {
  Default: 'https://pci-site-assets.s3.amazonaws.com/DemoEvent/Default.jpg', // set fallback poster card image
};

// Configure Lobby Image

const lobbyImageMap = {
  name: 'my-map',
  areas: [
    // {
    //   name: 'welcomeVideo',
    //   shape: 'poly',
    //   coords: [
    //     23,
    //     665, //bottom left
    //     42,
    //     498, //top left
    //     355,
    //     515, //top right
    //     345,
    //     675, //bottom right
    //   ],
    //   strokeColor: '',
    //   lineWidth: '4',
    // },
    {
      name: 'agenda',
      shape: 'poly',
      coords: [
        253,
        893, //bottom left
        275,
        735, //top left
        523,
        736, //top right
        515,
        890, //bottom right
      ],
      strokeColor: '',
      lineWidth: '4',
      href: '/agenda',
    },
    {
      name: 'posterhall',
      shape: 'poly',
      coords: [
        540,
        890, //bottom left
        550,
        740, //top left
        792,
        740, //top right
        787,
        882, //bottom right
      ],
      strokeColor: '',
      lineWidth: '4',
      href: '/posterhall',
    },
    {
      name: 'exhibithall',
      shape: 'poly',
      coords: [
        810,
        883, //bottom left
        818,
        740, //top left
        1065,
        713, //top right
        1070,
        867, //bottom right
      ],
      strokeColor: '',
      lineWidth: '4',
      href: '/exhibithall',
    },
    // {
    //   name: 'auditorium',
    //   shape: 'poly',
    //   coords: [
    //     1095,
    //     862, //bottom left
    //     1090,
    //     708, //top left
    //     1160,
    //     685, // middle top
    //     1370,
    //     575, // top right
    //     1385,
    //     822, //bottom right
    //     // 1280, 800, // middle bottom
    //   ],
    //   strokeColor: '',
    //   lineWidth: '4',
    //   href: '/auditorium',
    // },
    // {
    //   name: 'caricatures',
    //   shape: 'poly',
    //   coords: [
    //     1410,
    //     822, //bottom left
    //     1395,
    //     557, //top left
    //     1707,
    //     375, //top right
    //     1755,
    //     755, //bottom right
    //   ],
    //   strokeColor: '',
    //   lineWidth: '4',
    //   href: 'https://ocmvirtual.com/PerkinElmer',
    // },
    {
      name: 'signature',
      formalName: 'Signature',
      shape: 'poly',
      coords: [
        666,
        610, //bottom left
        670,
        430, //top left
        1048,
        464, //top right
        1060,
        640, //bottom right
      ],
      strokeColor: '',
      lineWidth: '4',
      href: '/signature',
    },
    {
      name: 'planetconnect',
      formalName: 'PlanetConnect',
      shape: 'poly',
      coords: [
        14,
        1196, //top left
        420,
        1196, //top right
        420,
        1322, //bottom right
        14,
        1322, //bottom left
      ],
      strokeColor: '',
      lineWidth: '4',
    },
  ],
};

const lobbyImage =
  'https://eventhorizon-assets-public.s3.amazonaws.com/sanofi/sgas2024/virtual_environment/Lobby-SGAS24-V5.jpg'; // location source of lobby image

const aiEnabled = false;

const perkinBoothMTS2021 =
  'https://pci-admin-kfc-portal.s3.amazonaws.com/PerkinBooth-MTS2021.png'; // location source of signature sponsor booth background

// True Configuration
const eventId = import.meta.env.VITE_EVENT_ID; // Sets the event id of the event
const accountId = import.meta.env.VITE_ACCOUNT_ID; // Sets the account id of the event
const buildVersion = import.meta.env.VITE_BUILD_VERSION; //Sets the build version of the app

const landingpageOnly = false;

const MerckSSOEnabled = false;
const OktaSSOEnabled = false; // internal testing

const demoEnabled = false;

const allowedRegistrationDomains = ['@']; // comma separated array of substrings of emails allowed to register for the event as the accountId account
const allowedSigninDomains = ['@']; // comma separated array of substrings of emails allowed to enter the event

const endpoint =
  import.meta.env.VITE_BACKEND_ENDPOINT ||
  'https://core-api.planetconnect.com/';

// eslint-disable-next-line no-console
console.log('Backend URL:', endpoint);

const registrationPage = 'https://planetconnect.com/'; // depricated -  used as the place where users could go to register when authorized but not registered

const drawerWidth = 240; // left side open navigation bar width
const rightDrawerWidth = 340; // right side chat width

const fallbackImages = {
  // fallback images for exhibitor and agenda cards
  exhibitCard: MeetingRoomOne,
  agendaCard: MeetingRoomOne,
};

const attendeeGuide = AttendeeGuide;
// const sponsorBanner = SponsorBanner

const DEFAULT_CACHE_TTL = import.meta.env.VITE_DEFAULT_CACHE_TTL
  ? // VITE_DEFAULT_CACHE_TTL was sent
    typeof import.meta.env.VITE_DEFAULT_CACHE_TTL === 'string'
    ? // it's a string, so parse to a number
      parseInt(import.meta.env.VITE_DEFAULT_CACHE_TTL, 10)
    : // it's a number, use as is
      import.meta.env.VITE_DEFAULT_CACHE_TTL
  : // no value sent, use one hour
    60 * 60;

// Change this to a date in the future or now to force a cache clear
export const CLEAR_CACHE_AFTER = moment('2023-10-02T00:20:00.000Z').valueOf();

const COMMIT_DATE = buildEnv.COMMIT_DATE;
const BUILD_DATE = buildEnv.BUILD_DATE;
const COMMIT = buildEnv.COMMIT;
const REF = buildEnv.REF;

// eslint-disable-next-line no-console
console.log(`Commit Date: ${COMMIT_DATE}`);
// eslint-disable-next-line no-console
console.log(`Build Date: ${BUILD_DATE}`);
// eslint-disable-next-line no-console
console.log(`Commit Sha: ${COMMIT}`);
// eslint-disable-next-line no-console
console.log(`Build Ref: ${REF}`);
// eslint-disable-next-line no-console
console.log(`Cache Time (Seconds): ${DEFAULT_CACHE_TTL}`);

export {
  // fallback protection
  posterSessionTopicImages, //used as fallback
  fallbackImages, // used as fallback
  landingPage, //used as fallback
  title, //used as fallback
  colors, //used as fallback
  faviconLocation, //used as fallback
  // true configuration
  endpoint,
  drawerWidth,
  rightDrawerWidth,
  lobbyImageMap,
  lobbyImage,
  eventId,
  accountId,
  buildVersion,
  // staged for remote configuration
  registrationPage,
  attendeeGuide,
  allowedRegistrationDomains,
  allowedSigninDomains,
  // perkinSpecific
  perkinBoothMTS2021,
  // SSO Attributes
  MerckSSOEnabled,
  OktaSSOEnabled,
  demoEnabled,
  // landing page specific
  landingpageOnly,
  DEFAULT_CACHE_TTL,
  COMMIT_DATE,
  BUILD_DATE,
  COMMIT,
  REF,
  aiEnabled,
};
