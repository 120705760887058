import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getConversationsClient,
  getContactsClient,
  setChatWindow,
} from '../Store/Reducers/twilio';
import getTwilioUserIdentity from '../Utilities/getTwilioUserIdentity';
import PersonIcon from '@material-ui/icons/Person';

import twilioCreateJoinOneOnOneConversation from '../Store/Actions/twilioCreateJoinOneOnOneConversation';
import twilioSubscribeToIdentity from '../Store/Actions/twilioSubscribeToIdentity';
// Redux

const useStyles = (theme) => ({});

const ChatProfile = ({ identity, size, photo_url, directory, onClick }) => {
  const dispatch = useDispatch();
  const conversationsClient = useSelector(getConversationsClient);
  // const contactsClient = useSelector(getContactsClient);
  const me = useSelector((state) => state.user.user);
  const user = useSelector((state) => state.twilio?.users?.[identity]);
  // const contact = useSelector((state) => state.twilio?.contacts?.[identity]);

  const [isCreatingChat, setIsCreatingChat] = useState(false);

  // console.log('🚀 ~ file: ChatProfile.js ~ line 23 ~ ChatProfile ~ user', user);
  // console.log(
  //   '🚀 ~ file: ChatProfile.js ~ line 39 ~ ChatProfile ~ getTwilioUserIdentity(me)',
  //   getTwilioUserIdentity(me)
  // );

  // const [user, setUser] = useState(null);
  useEffect(() => {
    (async () => {
      try {
        // console.log('directory', directory);
        // console.log('conversationsClient', conversationsClient);
        // console.log('identity', identity);
        // console.log('user', user);

        if (
          !directory &&
          conversationsClient &&
          identity &&
          (!user || user?.identity !== identity)
        ) {
          await dispatch(twilioSubscribeToIdentity(identity));
        }
        // eslint-disable-next-line no-empty
      } catch (e) {}
    })();
  }, [identity, user]);
  // useEffect(() => {
  //   // try {
  //   async () => {};
  //   if (
  //     !directory &&
  //     conversationsClient &&
  //     identity &&
  //     (!user || user?.identity !== identity)
  //   ) {
  //     // try {
  //     // const subscribe = await dispatch(twilioSubscribeToIdentity(identity));
  //     // console.log(`subscribing to ${identity}`);

  //     // } catch (e) {
  //     //   console.log('🚀 ~ file: ChatProfile.js ~ line 46 ~ useEffect ~ e', e);
  //     // }
  //     // if (!user?.isSubscribed) {
  //     // console.log(`subscribing to ${identity}`);
  //     // console.log(
  //     //   '🚀 ~ file: ChatProfile.js ~ line 66 ~ conversationsClient.getUser ~ conversationsClient',
  //     //   conversationsClient
  //     // );
  //     // conversationsClient
  //     //   .getUser(identity)
  //     //   .then((subscribedUser) => {
  //     //     // console.log(
  //     //     //   '🚀 ~ file: ChatProfile.js ~ line 61 ~ conversationsClient.getUser ~ subscribedUser',
  //     //     //   subscribedUser
  //     //     // );
  //     //     // // Save this user in my contacts list
  //     //     // if (!contact) {
  //     //     //   // console.log(`creating contact ${identity}`);
  //     //     //   contactsClient.set(identity, {
  //     //     //     identity,
  //     //     //     friendlyName: subscribedUser.friendlyName,
  //     //     //     // attributes: { ...subscribedUser.attributes },
  //     //     //     notes: '',
  //     //     //   });
  //     //     // } else {
  //     //     //   // console.log(`updating contact ${identity}`);
  //     //     //   contactsClient.set(identity, {
  //     //     //     ...contact,
  //     //     //     identity,
  //     //     //     friendlyName: subscribedUser.friendlyName,
  //     //     //     // attributes: { ...subscribedUser.attributes },
  //     //     //   });
  //     //     // }
  //     //   })
  //     //   .catch((error) => {
  //     //     // console.log('User has not registered', error.message);
  //     //   });
  //     // }
  //   }
  //   // } catch (e) {
  //   //   console.log('🚀 ~ file: ChatProfile.js ~ line 85 ~ useEffect ~ e', e);
  //   // }
  // }, [identity, user]);
  const isMe = user && me && getTwilioUserIdentity(me) === identity;

  const photoUrl = user?.attributes?.photo_url || photo_url;

  return (
    <div
      style={{
        position: 'relative',
        cursor: 'pointer',
        // border: '1px solid red',
        margin: 3,
        width: size,
        height: size,

        // width: '100%',
      }}
      onClick={async (e) => {
        if (user) {
          e.stopPropagation();
          if (onClick) {
            onClick();
          } else if (!directory) {
            console.log('creating chat ');
            try {
              if (!isMe && !isCreatingChat) {
                setIsCreatingChat(true);
                await dispatch(twilioCreateJoinOneOnOneConversation(identity));
                // dispatch(setOpenProfile(null));
                // dispatch(setEngagement('chat'));
                dispatch(setChatWindow(true));
                setIsCreatingChat(false);
              }
            } catch (error) {
              console.log('chat error: ', error.message);
            }
          }
        }

        // dispatch(setOpenConversation(null));
        // dispatch(setOpenProfile(user));
        // dispatch(setEngagement('profile'));
        // dispatch(setChatWindow(true));
      }}
    >
      <div
        style={{
          // ...styles.circle,
          borderRadius: '50%',

          width: size,
          height: size,
          // backgroundImage: `url(${
          //   photo_url ? photo_url : user?.attributes?.photo_url
          // })`,
          // backgroundSize: 'cover',
          // backgroundRepeat: 'no-repeat',
          // backgroundColor: 'rgba(0, 0, 0, 0.12)',
          // backgroundPosition: 'center',
          border: '1px solid rgba(0, 0, 0, 0.12)',
        }}
      >
        {!photoUrl ? (
          <PersonIcon
            // className={classes.linePhoto}
            style={{
              width: size,
              height: size,
              background: '#CCCCCC',
              borderRadius: '50%',
            }}
          />
        ) : (
          <img
            src={photoUrl}
            style={{ width: size, height: size, borderRadius: '50%' }}
            alt={`${identity} profile picture`}
          />
        )}
      </div>
      {!directory && user && (
        <div
          style={{
            minWidth:
              size >= 50 ? 17 : size > 20 ? 14 : size === '4rem' ? 17 : 8,
            height: size >= 50 ? 17 : size > 20 ? 14 : size === '4rem' ? 17 : 8,
            backgroundColor: user?.isOnline ? '#1DDD47' : 'white',
            borderRadius: 20,
            position: 'absolute',
            right: 0,
            // left: 40,
            bottom: -2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            // padding: 5,
            color: 'white',
            border: user?.isOnline ? '2px solid #fff' : '2px solid #757575',
          }}
        ></div>
      )}

      {/* {!isMe && user?.identity && (
        <button
          onClick={() => {
            dispatch(twilioCreateJoinOneOnOneConversation(user.identity));
          }}
        >
          start chat
          {user?.attributes?.photo_url}
        </button>
      )} */}
    </div>
  );
  // return (
  //   <div>
  //     <div>
  //       {isMe ? 'ME' : user?.identity} ({user?.isOnline ? 'online' : 'offline'})
  //     </div>
  // {!isMe && user?.identity && (
  //   <button
  //     onClick={() => {
  //       dispatch(twilioCreateJoinOneOnOneConversation(user.identity));
  //     }}
  //   >
  //     start chat
  //     {user?.attributes?.photo_url}
  //   </button>
  // )}
  //   </div>
  // );
};
const styles = {
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
  },

  circle: {
    borderRadius: 50,
    width: 50,
    height: 50,
    backgroundColor: '#b0b0b0',
    border: '1px solid rgba(0, 0, 0, 0.12)',
  },
};

export default ChatProfile;
