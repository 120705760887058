import React, { Component } from 'react';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Typography, Grid, Paper } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import PosterCard from './PosterCard';
import PosterListItem from './PosterListItem';
import PosterFull from './PosterFull';
import Dialog from '../../Components/DialogWrapper';
import { withRouter } from 'react-router';
import { eventId, aiEnabled } from '../../config';
import trackAction from '../../Store/Actions/tracker';
import Button from '@material-ui/core/Button';
import Pagination from '@material-ui/lab/Pagination';
import Switch from '@material-ui/core/Switch';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Hidden from '@material-ui/core/Hidden';
import CircularProgress from '@material-ui/core/CircularProgress';

// Configure Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import setBriefcase from '../../Store/Actions/setBriefcase';
import { queryApi } from '../../Services/queryApi';
import { configureStore } from '@reduxjs/toolkit';
import { getPosters, setPosters } from '../../Store/Reducers/poster';

const useStyles = (theme) => ({
  root: {},
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  filter: {
    // margin: '0% 0% 1% 0%',
    '& #filterInput': {
      backgroundColor: 'white',
      borderRadius: '5%',
      boxShadow: '0px 1px 1px 0px rgba(120,120,120,0.75)',
      '&:hover': {},
    },
  },
  paginationGrid: {
    alignSelf: 'center',
  },
  button: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      boxShadow: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  topicFilter: {
    fontWeight: '500',
    color: '#000000',
    fontSize: '1.2rem',
  },
  filterGrid: {
    alignItems: 'center',
    // paddingLeft: theme.spacing(0.5),
  },
  tabs: {
    display: 'inline-flex',
  },
  tabSingle: {
    minWidth: 'auto',
  },
});

class PosterSessions extends Component {
  // briefcaseSubscription;
  postersSubscription;

  constructor(props) {
    super(props);
    this.state = {
      posterSelected: false,
      posterIndex: null,
      posterId: null,
      postersFiltered: [],
      listFormat: false,
      filterString: '',
      filterCategory: 'topic',
      filtering: false,
      pageCountStart: 1,
      perPage: 36,
      page: 1,
      topicMap: null,
      topicsMenuItems: null,
      isRandom: false,
      randomizedPosters: [],
      randomPageArray: null,
      tabValue: 0,
      isAISuggestion: false,
      aiPosters: [],
    };
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  componentDidMount() {
    if (this.props.location.search) {
      const query = new URLSearchParams(this.props.location.search);
      for (let param of query.entries()) {
        if (param[0] === 'poster_id') {
          this.setState({
            posterSelected: true,
            posterId: param[1],
          });
        } else if (param[0] === 'ai_suggestion') {
          this.handleTabChange(null, 2);
        }
      }
    }

    // set randomPageArray
    let { poster } = this.props;

    const unfilteredPosters = poster?.data?.data;

    const posters = unfilteredPosters?.filter((poster) => {
      if (!this.state.filterString) {
        return true;
      }
      return poster.topic === this.state.filterString;
    });

    // const startIndex = (this.state.page - 1) * this.state.perPage;
    // const endIndex = Math.min(
    //   this.state.page * this.state.perPage - 1,
    //   posters?.length || 0
    // );

    const totalPages = Math.ceil((posters?.length || 0) / this.state.perPage);
    this.handleRandomPageArray(totalPages, posters);
    // end set randomPageArray

    // this.setState({ topicMap: JSON.parse(this.props.event.event.topic_map) });

    this.postersSubscription = this.props.getPoster(eventId);
    if (this.props.event?.cacheTTL) {
      this.postersSubscription.updateSubscriptionOptions({
        refetchOnMountOrArgChange: this.props.event.cacheTTL,
      });
    }
    // this.briefcaseSubscription = this.props.getBriefcase(eventId);

    const event = {
      action: 'Viewed',
      resource_type: 'PosterHall',
      resource_id: null,
      url_visited: '/posterhall',
      resource_account_id: null,
      resource_account_name: null,
      resource_name: null,
    };

    this.props.trackAction(event);

    let allTopics = Object.keys(JSON.parse(this.props.event.event.topic_map));
    allTopics.shift(); //remove Default from the array
    let topicsMenuItems;
    topicsMenuItems = allTopics?.map((topic) => {
      const filtered = this.props.poster?.data?.data?.filter((poster) => {
        return poster.topic === topic;
      });
      if (filtered?.length > 0) {
        return (
          <MenuItem
            value={topic}
            key={`topics_menu_item_${topic}`}
            style={{ whiteSpace: 'inherit' }}
          >
            <b>{topic}</b>
          </MenuItem>
        );
      }
    });

    let randomizedPosters = posters?.sort(() => Math.random() - 0.5);

    this.setState({
      topicsMenuItems: topicsMenuItems,
      topicMap: JSON.parse(this.props.event.event.topic_map),
      randomizedPosters,
    });
    this.scrollToTop();

    if (aiEnabled) {
      this.getAISuggestion();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props?.location?.search !== nextProps?.location?.search) {
      const query = new URLSearchParams(nextProps.location.search);
      for (let param of query.entries()) {
        if (param[0] === 'poster_id') {
          this.setState({
            posterSelected: true,
            posterId: param[1],
          });
        }
      }

      return true;
    }
    if (this.props.timestamp === nextProps.timestamp) {
      return true;
    }
    return false;
  }

  componentWillUnmount() {
    // if (this.briefcaseSubscription) this.briefcaseSubscription.unsubscribe();
    if (this.postersSubscription) this.postersSubscription.unsubscribe();
    // this.stopLiveNowCheck();
  }

  handleRandomPageArray = (totalPages, posters) => {
    let randomPageArray = [];
    for (let i = 0; i < totalPages; i++) {
      randomPageArray.push(i + 1);
    }
    randomPageArray = randomPageArray.sort(() => Math.random() - 0.5);
    if (this.state.randomPageArray) {
      if (this.state.randomPageArray?.length) {
        randomPageArray = this.state.randomPageArray;
      } else {
        let randomizedPosters = posters.sort(() => Math.random() - 0.5);
        this.setState({ randomizedPosters });
      }
    }

    this.setState({ randomPageArray });
  };

  togglePosterFullscreenOn = (event, id) => {
    this.setState({
      posterSelected: true,
      posterId: id,
    });
  };

  togglePosterFullscreenOff = () => {
    this.setState({
      posterSelected: false,
      posterId: null,
    });
  };

  handleGridListToggle = () => {
    this.setState({ listFormat: !this.state.listFormat });
  };

  handleFilterClick = (e, totalPages, posters) => {
    this.handleRandomPageArray(totalPages, posters);
    this.setState({
      filterString: e.target.value,
      // filterCategory: category,
      filtering: true,
      pageCountStart: 1,
      page: 1,
    });
  };

  handleRemoveFilter = (totalPages, posters) => {
    this.handleRandomPageArray(totalPages, posters);
    this.setState({
      filterString: '',
      filtering: false,
      pageCountStart: 1,
      page: 1,
    });
  };

  handlePageChange = (event, value, totalPages, posters) => {
    this.setState({
      page: value,
      pageCountStart: value * this.state.perPage - (this.state.perPage - 1),
    });
  };

  handleNextRandomSet = (totalPages, posters) => {
    let updatedRandomPageArray = this.state.randomPageArray;
    let currentPage = updatedRandomPageArray.shift();
    this.setState({
      page: currentPage,
      randomPageArray: updatedRandomPageArray,
      pageCountStart:
        currentPage * this.state.perPage - (this.state.perPage - 1),
      isRandom: true,
      tabValue: 1,
    });
    this.handleRandomPageArray(totalPages, posters);
    const event = {
      action: 'Clicked',
      resource_type: 'PosterHall / Next Random Set',
      resource_id: null,
      url_visited: '/posterhall',
      resource_account_id: null,
      resource_account_name: null,
      resource_name: null,
    };

    trackAction(event);
  };

  handleClose() {
    this.setState({
      posterSelected: false,
      posterIndex: null,
      posterId: null,
    });
  }

  handleUpvote = (index) => {
    const items = this.props.poster.data.data.map((poster) => ({
      ...poster,
    }));
    let item = { ...items[index] };

    if (item.liked === true) {
      item.count = item.count - 1;
      item.liked = false;
    } else {
      item.count = item.count + 1;
      item.liked = true;
    }

    items[index] = item;

    this.setState({ posters: items });
    // this.props.setPosters({ ...this.props.allPosters, data: items });
  };

  handleUpvotePosterFull = (posterId) => {
    const items = this.props.poster.data.data.map((poster) => ({
      ...poster,
    }));
    for (let i = 0; i < items.length; i++) {
      if (items[i].abstract_id === posterId) {
        if (items[i].liked === true) {
          items[i].count = items[i].count - 1;
          items[i].liked = false;
        } else {
          items[i].count = items[i].count + 1;
          items[i].liked = true;
        }
      }
    }

    this.setState({ posters: items });
    // this.props.setPosters({ ...this.props.allPosters, data: items });
  };

  handleGroupAdd = (index) => {
    const items = this.props.poster.data.data.map((poster) => ({
      ...poster,
    }));
    let item = { ...items[index] };

    if (item.groupInterested === true) {
      item.groupCount = item.groupCount - 1;
      item.groupInterested = false;
    } else {
      item.groupCount = item.groupCount + 1;
      item.groupInterested = true;
    }

    items[index] = item;

    this.setState({ posters: items });
    // this.props.setPosters({ ...this.props.allPosters, data: items });
  };

  handleGroupAddPosterFull = (posterId) => {
    const items = this.props.poster.data.data.map((poster) => ({
      ...poster,
    }));
    for (let i = 0; i < items.length; i++) {
      if (items[i].abstract_id === posterId) {
        if (items[i].groupInterested === true) {
          items[i].groupCount = items[i].groupCount - 1;
          items[i].groupInterested = false;
        } else {
          items[i].groupCount = items[i].groupCount + 1;
          items[i].groupInterested = true;
        }
      }
    }

    this.setState({ posters: items });
    // this.props.setPosters({ ...this.props.allPosters, data: items });
  };

  handleTabChange = (event, newValue) => {
    this.setState({
      tabValue: newValue,
      isRandom: newValue === 1 ? true : false,
      isAISuggestion: newValue === 2 ? true : false,
    });
    if (newValue === 1) {
      // this.handleNextRandomSet(totalPages, posters);
      const event = {
        action: 'Clicked',
        resource_type: 'PosterHall / Randomize',
        resource_id: null,
        url_visited: '/posterhall',
        resource_account_id: null,
        resource_account_name: null,
        resource_name: null,
      };

      if (newValue === 2) {
        const event = {
          action: 'Clicked',
          resource_type: 'PosterHall / AI Suggestion',
          resource_id: null,
          url_visited: '/posterhall',
          resource_account_id: null,
          resource_account_name: null,
          resource_name: null,
        };

        trackAction(event);
      }
    }
  };

  getAISuggestion = () => {
    if (!this.state.aiPosters.length) {
      Auth.currentSession()
        .then((data) => {
          axios({
            method: 'get',
            url: `/suggestions/${eventId}`,
            headers: {
              idtoken: data.idToken.jwtToken,
            },
          })
            .then((response) => {
              this.setState({
                aiPosters: response.data,
              });
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }

    const event = {
      action: 'Clicked',
      resource_type: 'PosterHall / AI Suggestion',
      resource_id: null,
      url_visited: '/posterhall',
      resource_account_id: null,
      resource_account_name: null,
      resource_name: null,
    };

    trackAction(event);
  };

  render() {
    console.log('Render Poster Session');

    const { classes, poster } = this.props;
    const unfilteredPosters = poster?.data?.data;

    let posters = unfilteredPosters?.filter((poster) => {
      if (!this.state.filterString) {
        return true;
      }
      return poster.topic === this.state.filterString;
    });
    if (this.state.isRandom) {
      posters = this.state.randomizedPosters;
    } else if (this.state.isAISuggestion) {
      posters = this.state.aiPosters;
    }

    const startIndex = (this.state.page - 1) * this.state.perPage;
    const endIndex = Math.min(
      this.state.page * this.state.perPage - 1,
      posters?.length || 0
    );

    const totalPages = Math.ceil((posters?.length || 0) / this.state.perPage);

    const allRoutes = JSON.parse(
      this.props.event.event.available_routes
    ).routes;
    let pageTitle = '';
    allRoutes.forEach((route) => {
      if (route.route === '/posterhall') {
        pageTitle = route.title;
      }
    });

    let sidebarOpen;
    if (this.props.sidebar.chatOpen || this.props.sidebar.open) {
      sidebarOpen = true;
    } else {
      sidebarOpen = false;
    }

    let pageBody;
    let loadingGraphic = (
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <br />
        <br />
        <CircularProgress color="inherit" />
        <Typography variant="h4" color="textPrimary">
          Loading...
        </Typography>
      </Grid>
    );
    let noResultsFound = (
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <br />
        <br />
        <Typography variant="h4" color="textPrimary">
          No Results Found
        </Typography>
      </Grid>
    );
    let contentLoading = (
      <Grid container layout={'row'} spacing={2}>
        <Grid item xs={12}>
          <Paper>
            <Typography
              style={{ textAlign: 'center', padding: '16px 0px 8px 0px' }}
              variant="h4"
              color="textPrimary"
            >
              {pageTitle}
            </Typography>
          </Paper>
        </Grid>
        {this.state.filtering && posters.length === 0
          ? noResultsFound
          : loadingGraphic}
      </Grid>
    );

    let allTopics = Object.keys(JSON.parse(this.props.event.event.topic_map));
    allTopics.shift(); //remove Default from the array
    let topicsMenuItems = allTopics.map((topic) => {
      return (
        <MenuItem value={topic} key={topic}>
          <b>{topic}</b>
        </MenuItem>
      );
    });

    let sortModes = (
      <Tabs
        // className={classes.tabs}
        value={this.state.tabValue}
        onChange={(event, tabValue) => {
          this.handleTabChange(event, tabValue);
          if (tabValue === 1) {
            this.handleNextRandomSet(totalPages, posters);
          }
        }}
        indicatorColor="primary"
        textColor="primary"
        // variant="scrollable"
        style={{ display: 'inline-flex', marginRight: '24px' }}
      >
        <Tab
          style={{ minWidth: 'auto' }}
          // className={classes.tabSingle}
          label={'Ordered'}
          to="/event"
        />
        <Tab
          style={{ minWidth: 'auto' }}
          // className={classes.tabSingle}
          label={'Random'}
          to="/event"
        />
        {aiEnabled && this.state.aiPosters.length ? (
          <Tab
            style={{ minWidth: 'auto' }}
            label={'AI Suggested'}
            to="/event"
          />
        ) : null}
      </Tabs>
    );

    let randomize = (
      <Button
        variant="contained"
        // className={classes.button}
        onClick={() => this.handleNextRandomSet(totalPages, posters)}
        style={{
          margin: '4px 0px 0px 8px',
          width: '11rem',
          padding: '4px',
          color: 'white',
          backgroundColor: this.props.event.event.main_color,
          '&:hover': {
            backgroundColor: this.props.event.event.light_color,
            boxShadow: 'none',
          },
        }}
      >
        Next Random Page
      </Button>
    );

    let paginationRandomize = (
      <>
        {!this.state.isRandom ? (
          <Grid
            align="left"
            key="pagination"
            item
            // xs={12}
            // sm={9}
            className={classes.paginationGrid}
          >
            <Pagination
              count={totalPages}
              page={this.state.page}
              onChange={this.handlePageChange}
              boundaryCount={2}
              siblingCount={1}
            />
          </Grid>
        ) : (
          <Grid
            item
            align="left"
            // xs={12} sm={3}
          >
            {randomize}
          </Grid>
        )}
      </>
    );

    let topicFilter = (
      <>
        <Grid item align="right" xs={12} sm={6} style={{ marginRight: '8px' }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              <Typography
                variant="body1"
                // className={classes.topicFilter}
                style={{
                  fontWeight: '500',
                  color: '#000000',
                  fontSize: '1.2rem',
                }}
              >
                Topic Filter
              </Typography>
            </InputLabel>
            <Select
              value={this.state.filterString}
              label="Topic"
              onChange={(e) => this.handleFilterClick(e, totalPages, posters)}
            >
              {this.state.topicsMenuItems}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          align="left"
          // xs={12} sm={2}
          style={{ alignSelf: 'end' }}
        >
          {this.state.filtering ? (
            <Button
              variant="contained"
              onClick={this.handleRemoveFilter}
              className={classes.button}
              style={{
                marginTop: '4px',
                width: '11rem',
                padding: '4px 16px',
              }}
            >
              Remove Filter
            </Button>
          ) : null}
        </Grid>
      </>
    );

    let gridListToggle = (
      <Hidden xsDown>
        <Button onClick={this.handleGridListToggle}>
          Grid
          <Switch
            checked={this.state.listFormat}
            onChange={this.handleGridListToggle}
            color="primary"
            name="listFormatSwitch"
            inputProps={{ 'aria-label': 'listFormatSwitch' }}
          />
          List
        </Button>
      </Hidden>
    );

    if (this.state.listFormat) {
      pageBody = (
        <Grid
          container
          className={classes.gridContainer}
          layout={'row'}
          spacing={2}
        >
          <Grid item xs={12}>
            <Paper>
              <Typography
                style={{ textAlign: 'center', padding: '16px 0px 8px 0px' }}
                variant="h4"
                color="textPrimary"
              >
                {pageTitle}
              </Typography>
            </Paper>
          </Grid>
          <Grid
            container
            style={{ alignItems: 'center' }}
            layout={'row'}
            spacing={2}
          >
            {!this.state.isAISuggestion ? (
              <Grid item container xs={6}>
                {paginationRandomize}
              </Grid>
            ) : null}
            <Grid item xs={!this.state.isAISuggestion ? 6 : 12} align="right">
              {sortModes}
              {gridListToggle}
            </Grid>
            {!this.state.isAISuggestion ? (
              <Grid item container xs={12} style={{ marginLeft: '8px' }}>
                {topicFilter}
              </Grid>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            {this.state.isAISuggestion && !posters.length ? (
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <br />
                <br />
                <Typography variant="h4" color="textPrimary">
                  No AI Recommendations Available at This Time
                </Typography>
              </Grid>
            ) : null}
            {posters
              ?.filter((poster, index) => {
                if (this.state.isAISuggestion) {
                  this.state.aiPosters.includes(poster.abstract_id);
                  return true;
                } else if (index >= startIndex && index <= endIndex) {
                  return true;
                } else {
                  return false;
                }
              })
              ?.map((poster, index) => {
                return (
                  <PosterListItem
                    key={index}
                    topicMap={this.state.topicMap}
                    index={index}
                    poster={poster}
                    toggleFullscreenHandlerOn={this.togglePosterFullscreenOn.bind(
                      this
                    )}
                    handleUpvote={() => this.handleUpvote(index)}
                    handleGroupAdd={() => this.handleGroupAdd(index)}
                  />
                );
              })}
            <Dialog
              open={this.state.posterSelected}
              handleClose={this.handleClose.bind(this)}
              scroll="body"
            >
              {posters?.length ? (
                <PosterFull
                  posterId={this.state.posterId}
                  allPosters={posters}
                  closeBooth={this.togglePosterFullscreenOff.bind(this)}
                  handleUpvote={() =>
                    this.handleUpvotePosterFull(this.state.posterId)
                  }
                  handleGroupAdd={() =>
                    this.handleGroupAddPosterFull(this.state.posterId)
                  }
                />
              ) : null}
            </Dialog>
          </Grid>
          {!this.state.isAISuggestion ? (
            <Grid key="pagination" item xs={12}>
              <Pagination
                count={totalPages}
                page={this.state.page}
                onChange={this.handlePageChange}
              />
            </Grid>
          ) : null}
        </Grid>
      );
    } else {
      pageBody = (
        <Grid
          container
          className={classes.gridContainer}
          layout={'row'}
          spacing={2}
        >
          <Grid item xs={12}>
            <Paper>
              <Typography
                style={{ textAlign: 'center', padding: '16px 0px 8px 0px' }}
                variant="h4"
                color="textPrimary"
              >
                {pageTitle}
              </Typography>
              <Typography
                style={{ textAlign: 'center', padding: '0px 16px 16px 16px' }}
                variant="subtitle1"
                color="textPrimary"
              >
                Please vote for your favorite posters using the Like icon below
                each poster before June 10th. <br /> This will be used to
                determine the Scientist Choice Awards to be announced on June
                12th during the Plenary Session.
              </Typography>
            </Paper>
          </Grid>
          <Grid
            container
            // className={classes.filterGrid}
            style={{ alignItems: 'center' }}
            layout={'row'}
            spacing={0}
          >
            {!this.state.isAISuggestion ? (
              <Grid item container xs={6}>
                {paginationRandomize}
              </Grid>
            ) : null}
            <Grid item xs={!this.state.isAISuggestion ? 6 : 12} align="right">
              {sortModes}
              {gridListToggle}
            </Grid>
            {!this.state.isAISuggestion ? (
              <Grid item container xs={12} style={{ marginLeft: '8px' }}>
                {topicFilter}
              </Grid>
            ) : null}
          </Grid>
          {this.state.isAISuggestion && !posters.length ? (
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <br />
              <br />
              <Typography variant="h4" color="textPrimary">
                No AI Recommendations Available at This Time
              </Typography>
            </Grid>
          ) : null}
          {posters
            ?.filter((poster, index) => {
              if (this.state.isAISuggestion) {
                this.state.aiPosters.includes(poster.abstract_id);
                return true;
              } else if (index >= startIndex && index <= endIndex) {
                return true;
              } else {
                return false;
              }
            })
            ?.map((poster, index) => {
              return (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={6}
                  md={sidebarOpen ? 6 : 4}
                  lg={sidebarOpen ? 4 : 3}
                  xl={sidebarOpen ? 3 : 2}
                >
                  {this.state.topicMap && (
                    <PosterCard
                      key={index}
                      topicMap={this.state.topicMap}
                      poster={poster}
                      index={index}
                      toggleFullscreenHandlerOn={this.togglePosterFullscreenOn.bind(
                        this
                      )}
                      handleUpvote={() => this.handleUpvote(index)}
                      handleGroupAdd={() => this.handleGroupAdd(index)}
                    />
                  )}
                </Grid>
              );
            })}
          {!this.state.isAISuggestion ? (
            <Grid key="pagination" item xs={12}>
              <Pagination
                count={totalPages}
                page={this.state.page}
                onChange={this.handlePageChange}
              />
            </Grid>
          ) : null}
          <Dialog
            open={this.state.posterSelected}
            handleClose={this.handleClose.bind(this)}
            scroll="body"
          >
            {posters?.length ? (
              <PosterFull
                posterId={this.state.posterId}
                allPosters={posters}
                closeBooth={this.togglePosterFullscreenOff.bind(this)}
                handleUpvote={(posterId) =>
                  this.handleUpvotePosterFull(posterId)
                }
                handleGroupAdd={() =>
                  this.handleGroupAddPosterFull(this.state.posterId)
                }
              />
            ) : null}
          </Dialog>
        </Grid>
      );
    }

    let page =
      posters?.length > 0 || this.state.isAISuggestion
        ? pageBody
        : contentLoading;

    return page;
  }
}

function mapStateToProps(state) {
  return {
    // briefcase: queryApi.endpoints.getBriefcase.select(eventId)(state),
    // briefcase: state.queryApi.queries?.[`getBriefcase("${eventId}")`],
    user: state.user,
    sidebar: state.sidebar,
    debug: state.debug,
    event: state.event,
    // poster: queryApi.endpoints.getPosters.select(eventId)(state),
    poster: state.queryApi.queries?.[`getPosters("${eventId}")`],
    allPosters: getPosters(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      // setBriefcase: setBriefcase,
      getPoster: (eventId) => queryApi.endpoints.getPosters.initiate(eventId),
      getBriefcase: (eventId) =>
        queryApi.endpoints.getBriefcase.initiate(eventId),
      setPosters: (data) => dispatch(setPosters(data)),
      trackAction: trackAction,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(useStyles)(PosterSessions)));
